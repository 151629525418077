body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: transparent;
  text-decoration: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* lib css changes*/

.image-gallery-svg {
  opacity: 0.3;
}
.image-gallery-svg:hover {
  opacity: 1;
}

.image-gallery-right-nav .image-gallery-svg,
.image-gallery-left-nav .image-gallery-svg {
  height: 60px !important;
  width: 30px !important;
}

.image-gallery-icon:hover {
  color: white !important;
}

.image-gallery-bullets .image-gallery-bullet:hover {
  background: white !important;
  border: 1px solid white !important;
  opacity: 1 !important;
}

.image-gallery-bullets .image-gallery-bullet.active {
  opacity: 1 !important;
}

.image-gallery-bullets .image-gallery-bullet {
  opacity: 0.5 !important;
}

.toggle-to-erase {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2F3234;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #7EC242;
}

input:focus + .slider {
  box-shadow: 0 0 1px #7EC242;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  box-shadow: 1px 1px 2px;
}

.slider.round:before {
  border-radius: 50%;
}